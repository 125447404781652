import { createContext, FC } from 'react';
import { CompanyCapacityInfo } from '../CapacityTypes';
import { searchData } from '../CapacitySearchData';

export interface CapacityContextType {
    results: CompanyCapacityInfo[];

}

export const CarrierCapacityContext = createContext<CapacityContextType>({} as CapacityContextType)

const CarrierCapacityWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
const results = searchData // This is where the data would be fetched from the API

    return (
        <CarrierCapacityContext.Provider value={{results: results}}>
            {children}
        </CarrierCapacityContext.Provider>
    )
}

export default CarrierCapacityWrapper;