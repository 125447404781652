import { useMutation, useQuery } from 'react-query';
import {
  getDATRateview,
  getRateCast,
  getRateSonarScore,
  submitRate,
  getRTSRateHistory,
  getRTSMiles,
  getHubtekRate,
  getCustomerCodes,
} from './ratesHttp';
import { numberToWholeCurrencyString } from 'utils/numberUtils';
import {
  RateCastApiResultsToDatRate,
  RateQueryToRateBody,
  RateViewApiResultToDatRate,
  SonarScoreApiResultToSonarData,
  TrailerType,
  NewRateQuery,
  HubtekRequestBody,
  HubtekRequestQuery,
} from './ratesTypes';
import useSnackbar from '../../hooks/useSnackbar';
import * as FirebaseUtils from '../../utils/firebaseUtils';
import { getRegionByState } from '../rateProAdmin/rateProAdminRegions';
import { RateAdjustmentApiResult } from 'features/rateProAdmin/rateAdminTypes';
import { getMpactAPIData } from './mpact/mpactHttp';
import { MpactApiRequest } from './mpact/mpactTypes';
import { useFeatureFlags } from 'utils/featureFlagContext';

const getSuggestedBuyRateByRegion = async (
  originState: string | undefined,
  rateAdjustmenApiResult: RateAdjustmentApiResult[] | undefined,
  sonarScore?: number,
  rateViewAverage?: number,
  rateViewHigh?: number,
  rateViewLow?: number,
  rtsHistoryRateAverage?: number,
  rtsHistoryLoads?: number,
  equipmentType?: TrailerType,
  fuelCostPerTrip: number = 0,
) => {
  if (
    originState === undefined ||
    rateViewAverage === undefined ||
    rateViewHigh === undefined ||
    rateViewLow === undefined ||
    equipmentType === undefined
  ) {
    return undefined;
  }

  const state = originState;

  const region = getRegionByState(state);

  const regionAdjustment = rateAdjustmenApiResult?.find(
    (e) => e.region === region || e.region === 'all',
  );

  if (!regionAdjustment) {
    const rate = getSuggestedBuyRate(
      sonarScore,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      rtsHistoryRateAverage,
      rtsHistoryLoads,
      equipmentType,
      fuelCostPerTrip,
    );

    return rate ? numberToWholeCurrencyString(rate) : undefined;
  }

  if (equipmentType.toUpperCase() === TrailerType.VAN) {
    let ratetoUse = rateViewAverage + fuelCostPerTrip;
    if (sonarScore !== undefined) {
      if (
        sonarScore >= parseFloat(regionAdjustment.lowVanSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.lowVanSonarEnd)
      ) {
        ratetoUse = rateViewLow + fuelCostPerTrip;
      }

      if (
        sonarScore >= parseFloat(regionAdjustment.averageVanSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.averageVanSonarEnd)
      ) {
        ratetoUse = rateViewAverage + fuelCostPerTrip;
      }

      if (
        sonarScore >= parseFloat(regionAdjustment.highVanSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.highVanSonarEnd)
      ) {
        ratetoUse = rateViewHigh + fuelCostPerTrip;
      }
    }

    if (
      rtsHistoryLoads &&
      rtsHistoryLoads >= parseFloat(regionAdjustment.vanNumberOfLoads)
    ) {
      if (regionAdjustment.vanRtsRate.toUpperCase() === 'LOWER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip < ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }

      if (regionAdjustment.vanRtsRate.toUpperCase() === 'HIGHER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip > ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }
    }
    return numberToWholeCurrencyString(ratetoUse);
  }

  if (equipmentType.toUpperCase() === TrailerType.REEFER) {
    let ratetoUse = rateViewAverage + fuelCostPerTrip;
    if (sonarScore !== undefined) {
      if (
        sonarScore >= parseFloat(regionAdjustment.lowReeferSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.lowReeferSonarEnd)
      ) {
        ratetoUse = rateViewLow + fuelCostPerTrip;
      }

      if (
        sonarScore >=
          parseFloat(regionAdjustment.averageReeferSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.averageReeferSonarEnd)
      ) {
        ratetoUse = rateViewAverage + fuelCostPerTrip;
      }

      if (
        sonarScore >= parseFloat(regionAdjustment.highReeferSonarBeginning) &&
        sonarScore <= parseFloat(regionAdjustment.highReeferSonarEnd)
      ) {
        ratetoUse = rateViewHigh + fuelCostPerTrip;
      }
    }

    if (
      rtsHistoryLoads &&
      rtsHistoryLoads >= parseFloat(regionAdjustment.reeferNumberOfLoads)
    ) {
      if (regionAdjustment.reeferRtsRate.toUpperCase() === 'LOWER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip < ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }

      if (regionAdjustment.reeferRtsRate.toUpperCase() === 'HIGHER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip > ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }
      return numberToWholeCurrencyString(ratetoUse);
    }
    return numberToWholeCurrencyString(ratetoUse);
  }

  if (equipmentType.toUpperCase() === TrailerType.FLATBED) {
    let ratetoUse = rateViewAverage + fuelCostPerTrip;

    if (regionAdjustment.flatbedDatRate.toUpperCase() === 'LOW') {
      ratetoUse = rateViewLow + fuelCostPerTrip;
    }

    if (regionAdjustment.flatbedDatRate.toUpperCase() === 'HIGH') {
      ratetoUse = rateViewHigh + fuelCostPerTrip;
    }

    if (
      rtsHistoryLoads &&
      rtsHistoryLoads >= parseFloat(regionAdjustment.flatbedNumberOfLoads)
    ) {
      if (regionAdjustment.flatbedRtsRate.toUpperCase() === 'LOWER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip < ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }
      if (regionAdjustment.flatbedRtsRate.toUpperCase() === 'HIGHER') {
        if (
          rtsHistoryRateAverage &&
          rtsHistoryRateAverage + fuelCostPerTrip > ratetoUse
        ) {
          return numberToWholeCurrencyString(
            rtsHistoryRateAverage + fuelCostPerTrip,
          );
        }
      }
      return numberToWholeCurrencyString(ratetoUse);
    }
    return numberToWholeCurrencyString(ratetoUse);
  }

  return numberToWholeCurrencyString(rateViewAverage + fuelCostPerTrip);
};

const getSuggestedBuyRate = (
  sonarScore?: number,
  rateViewAverage?: number,
  rateViewHigh?: number,
  rateViewLow?: number,
  rtsHistoryRateAverage?: number,
  rtsHistoryLoads?: number,
  equipmentType?: TrailerType,
  fuelCostPerTrip: number = 0,
) => {
  if (
    rateViewAverage === undefined ||
    rateViewHigh === undefined ||
    rateViewLow === undefined ||
    equipmentType === undefined
  ) {
    return undefined;
  }

  if (
    (equipmentType.toUpperCase() === TrailerType.VAN ||
      equipmentType.toUpperCase() === TrailerType.REEFER) &&
    sonarScore !== undefined &&
    (rtsHistoryLoads === undefined || rtsHistoryLoads < 5)
  ) {
    if (sonarScore >= 1) {
      return rateViewAverage + fuelCostPerTrip;
    }
    if (sonarScore <= -1) {
      return rateViewLow + fuelCostPerTrip;
    }
  }

  if (rtsHistoryRateAverage !== undefined && rtsHistoryLoads !== undefined) {
    if (rtsHistoryLoads >= 5) {
      return rtsHistoryRateAverage <= rateViewAverage
        ? rtsHistoryRateAverage + fuelCostPerTrip
        : rateViewAverage + fuelCostPerTrip;
    }
    return rateViewAverage + fuelCostPerTrip;
  }

  return rateViewAverage + fuelCostPerTrip;
};

export const useMpactData = (searchQuery: NewRateQuery | undefined) => {
  const MpactDataResults = useQuery(
    ['MpactData', JSON.stringify(searchQuery)],
    async () => {
      const mpactRequestData: MpactApiRequest = {
        pickupCity: searchQuery?.pickupCity,
        pickupState: searchQuery?.pickupState,
        pickupPostalCode: searchQuery?.pickupPostalCode,
        deliveryCity: searchQuery?.deliveryCity,
        deliveryState: searchQuery?.deliveryState,
        deliveryPostalCode: searchQuery?.deliveryPostalCode,
        trailerType: searchQuery?.trailerType,
      };

      return await getMpactAPIData(mpactRequestData);
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );
  return {
    MpactDataResults: {
      ...MpactDataResults,
      data: MpactDataResults.data,
      error: MpactDataResults.error as Error,
    },
  };
};

export const useHubtekData = (
  searchQuery: NewRateQuery | undefined,
  sellRateSearchQuery: HubtekRequestQuery | undefined,
) => {
  const HubtekDataResults = useQuery(
    ['HubtekData', JSON.stringify({ ...searchQuery, ...sellRateSearchQuery })],
    async () => {
      const hubtekRequestData: HubtekRequestBody = {
        origin_city: searchQuery?.pickupCity,
        origin_state: searchQuery?.pickupState,
        delivery_city: searchQuery?.deliveryCity,
        delivery_state: searchQuery?.deliveryState,
        trailer_type: searchQuery?.trailerType || 'Van',
        identifiers: [
          sellRateSearchQuery?.tolerance
            ? sellRateSearchQuery.tolerance
            : 'rateProModerate',
        ],
        extra_charges_and_restrictions: sellRateSearchQuery?.accessorials
          ? sellRateSearchQuery?.accessorials
          : [],
        stops_quantity: sellRateSearchQuery?.stops
          ? sellRateSearchQuery?.stops
          : 0,
      };

      return await getHubtekRate(hubtekRequestData);
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );
  return {
    HubtekDataResults: {
      ...HubtekDataResults,
      data: HubtekDataResults.data,
      error: HubtekDataResults.error as Error,
    },
  };
};

export const useRateData = (searchQuery: NewRateQuery | undefined) => {
  const { showSnackbar } = useSnackbar();
  const { isRtsMilesServiceFallbackEnabled } = useFeatureFlags();

  const RateViewResults = useQuery(
    ['RateView', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        const result = await getDATRateview(RateQueryToRateBody(searchQuery));

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.DAT_RATEVIEW,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            mileage: result.rate.mileage,
            reports: result.rate.reports,
            companies: result.rate.companies,
            averageFuelSurchargePerMileUsd:
              result.rate.averageFuelSurchargePerMileUsd,
            perMile: `highUsd = ${result.rate.perMile?.highUsd}, lowUsd = ${result.rate.perMile?.lowUsd}, rateUsd = ${result.rate.perMile?.rateUsd}`,
            averageFuelSurchargePerTripUsd:
              result.rate.averageFuelSurchargePerTripUsd,
            standardDeviation: result.rate.standardDeviation,
            perTrip: `highUsd = ${result.rate.perTrip?.highUsd}, lowUsd = ${result.rate.perTrip?.lowUsd}, rateUsd = ${result.rate.perTrip?.rateUsd}`,
            escalation: `timeframe = ${result.escalation.timeframe}, destination ${result.escalation.destination.name}  ${result.escalation.destination.type}, origin = ${result.escalation.origin.name} ${result.escalation.origin.type}`,
            searchDate: new Date(),
            description: 'Check Rates - DAT Rateview Results',
          },
        );
        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const RTSHistoryRateResults = useQuery(
    ['RTSHistoryRate', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        const result = await getRTSRateHistory(
          RateQueryToRateBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RTS_HISTORY,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            mileage: result.rate.mileage,
            reports: result.rate.reports,
            companies: result.rate.companies,
            averageFuelSurchargePerMileUsd:
              result.rate.averageFuelSurchargePerMileUsd,
            perMile: `highUsd = ${result.rate.perMile?.highUsd}, lowUsd = ${result.rate.perMile?.lowUsd}, rateUsd = ${result.rate.perMile?.rateUsd}`,
            averageFuelSurchargePerTripUsd:
              result.rate.averageFuelSurchargePerTripUsd,
            standardDeviation: result.rate.standardDeviation,
            perTrip: `highUsd = ${result.rate.perTrip?.highUsd}, lowUsd = ${result.rate.perTrip?.lowUsd}, rateUsd = ${result.rate.perTrip?.rateUsd}`,
            escalation: `timeframe = ${result.escalation.timeframe}, destination ${result.escalation.destination.name}  ${result.escalation.destination.type}, origin = ${result.escalation.origin.name} ${result.escalation.origin.type}`,
            searchDate: new Date(),
            description: 'Check Rates - RTS History Results',
          },
        );
        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  let mileage = RateViewResults.data?.rate.mileage;

  const RTSMilesResults = useQuery(
    ['RTSMiles', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        const requestObject = [
          {
            routeId: crypto.randomUUID(),
            hasHazmat: false, // TODO, update to be dynamic when we add accessorials
            avoidTolls: false,
            bordersOpen: true,
            caller: 4, // 4 is RyanTrans, signifies we are the team/app using the service
            mileageServiceProvider: 2, // 2 is using HERE service provider
            addresses: [
              {
                city: searchQuery.pickupCity,
                state: searchQuery.pickupState,
                postalCode: searchQuery.pickupPostalCode,
              },
              {
                city: searchQuery.deliveryCity,
                state: searchQuery.deliveryState,
                postalCode: searchQuery.deliveryPostalCode,
              },
            ],
          },
        ];
        const result = await getRTSMiles(requestObject);
        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RTS_HISTORY,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            mileage: result[0].totalMiles,
            searchDate: new Date(),
            description: 'Check Mileage - RTS Miles Service Results',
          },
        );
        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (
    RTSMilesResults.data?.[0].totalMiles &&
    !mileage &&
    isRtsMilesServiceFallbackEnabled
  ) {
    mileage = Math.ceil(Number(RTSMilesResults.data?.[0].totalMiles));
  }

  // Rate Cast needs either data from RateView or RTSMiles
  const RateCastResults = useQuery(
    ['RateCast', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery && mileage) {
        const result = await getRateCast(RateQueryToRateBody(searchQuery));

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RTS_HISTORY,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            perMile: result
              .map(
                (e) =>
                  `highUsd = ${e.rate.perMile?.highUsd}, lowUsd = ${e.rate.perMile?.lowUsd}, rateUsd = ${e.rate.perMile?.rateUsd}`,
              )
              .join(', '),
            escalation: result
              .map(
                (e) =>
                  `timeframe = ${e.escalation.timeframe}, totalGranularity = ${e.escalation.totalGranularity}, destination ${e.escalation.destination.name}  ${e.escalation.destination.type}, origin = ${e.escalation.origin.name} ${e.escalation.origin.type}`,
              )
              .join(', '),
            searchDate: new Date(),
            description: 'Check Rates - DAT Ratecast Results',
          },
        );
        return result;
      }
    },
    {
      enabled: searchQuery !== undefined && mileage !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const SonarScoreResults = useQuery(
    ['SonarScore', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        const result = await getRateSonarScore(
          RateQueryToRateBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RATE_SONAR_SCORES,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            equip_type: result.equip_type,
            origin_market: result.origin_market,
            destination_market: result.destination_market,
            origin_kma: result.origin_kma,
            dest_kma: result.dest_kma,
            lane_score_summary: result.lane_score_summary,
            origin_city: result.origin_city,
            origin_state: result.origin_state,
            dest_City: result.dest_City,
            dest_state: result.dest_state,
            searchDate: new Date(),
            description: 'Check Rates - Sonar Scores',
          },
        );

        return SonarScoreApiResultToSonarData(result);
      }
    },
    {
      enabled:
        searchQuery !== undefined &&
        searchQuery.trailerType?.toUpperCase() !== TrailerType.FLATBED,
      refetchOnWindowFocus: false,
    },
  );

  const SubmitRateResults = useMutation(submitRate, {
    onSuccess: (data) => {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.RATE_PRO,
        FirebaseUtils.FirebasePages.YOUR_RATES,
        {
          pickupLocation: `${searchQuery?.pickupCity}, ${searchQuery?.pickupState}`,
          deliveryLocation: `${searchQuery?.deliveryCity}, ${searchQuery?.deliveryState}`,
          trailerType: searchQuery?.trailerType,
          customerCode: searchQuery?.customerCode,
          reason: SubmitRateResults.variables?.reason,
          rateId: data.rateId,
          customerRate: SubmitRateResults.variables?.customerRate,
          suggestedBuyRate: SubmitRateResults.variables?.suggestedBuyRate,
          searchDate: new Date(),
          description: 'Your customers rates',
        },
      );
      showSnackbar({
        message: `Rate successfully submitted.`,
        type: 'success',
      });
    },
    onError: (error: Error) => {
      if (error.message === 'Invalid customer id.') {
        showSnackbar({
          message: `Failed to submit rate. Invalid customer id.`,
          type: 'error',
        });
      } else {
        showSnackbar({
          message: `Failed to submit rate.`,
          type: 'error',
        });
      }
    },
  });

  const sonarScore = SonarScoreResults.data?.score;
  const rateViewAverageWithoutFuel = RateViewResults.data?.rate.perTrip.rateUsd;
  const rateViewHighWithoutFuel = RateViewResults.data?.rate.perTrip.highUsd;
  const rateViewLowWithoutFuel = RateViewResults.data?.rate.perTrip.lowUsd;

  const rateViewFuelCostPerTrip =
    RateViewResults.data?.rate.averageFuelSurchargePerTripUsd || 0;
  const rateViewAverage =
    (rateViewAverageWithoutFuel ?? 0) + rateViewFuelCostPerTrip;
  const rateViewHigh = (rateViewHighWithoutFuel ?? 0) + rateViewFuelCostPerTrip;
  const rateViewLow = (rateViewLowWithoutFuel ?? 0) + rateViewFuelCostPerTrip;

  const rtsHistoryRateAverage =
    RTSHistoryRateResults.data?.rate.perTrip.rateUsd;
  const rtsHistoryLoads = RTSHistoryRateResults.data?.rate.reports;

  const rateViewFuelCostPerMile =
    RateViewResults.data?.rate.averageFuelSurchargePerMileUsd;

  return {
    RateViewResults: {
      ...RateViewResults,
      data: RateViewResults.data
        ? RateViewApiResultToDatRate(RateViewResults.data)
        : undefined,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      error: RateViewResults.error as Error,
      rateViewFuelCostPerTrip,
    },
    RTSHistoryRateResults: {
      ...RTSHistoryRateResults,
      data: RTSHistoryRateResults.data
        ? RateViewApiResultToDatRate(RTSHistoryRateResults.data)
        : undefined,
      error: RTSHistoryRateResults.error as Error,
    },
    RTSMilesResults: {
      ...RTSMilesResults,
      data: {
        totalMiles: Math.ceil(Number(RTSMilesResults.data?.[0].totalMiles)),
        routeId: RTSMilesResults.data?.[0].routeId,
      },
      error: RTSMilesResults.error as Error,
    },
    RateCastResults: {
      ...RateCastResults,
      data:
        RateCastResults.data && mileage
          ? RateCastApiResultsToDatRate(
              RateCastResults.data,
              mileage,
              rateViewFuelCostPerTrip,
              rateViewFuelCostPerMile,
            )
          : undefined,
      error: RateCastResults.error as Error,
    },
    SonarScoreResults: {
      ...SonarScoreResults,
      error: SonarScoreResults.error as Error,
    },
    getSuggestedBuyRateByRegion: async (
      rateAdjustmenApiResult: RateAdjustmentApiResult[] | undefined,
    ) => {
      return getSuggestedBuyRateByRegion(
        searchQuery?.pickupState,
        rateAdjustmenApiResult,
        sonarScore,
        rateViewAverageWithoutFuel,
        rateViewHighWithoutFuel,
        rateViewLowWithoutFuel,
        rtsHistoryRateAverage,
        rtsHistoryLoads,
        searchQuery?.trailerType,
        rateViewFuelCostPerTrip,
      );
    },
    submitRate: async (
      suggestedBuyRate: number,
      reason: string,
      customerRate: number,
      searchQuery: NewRateQuery,
    ) => {
      if (searchQuery && RateViewResults && RateViewResults.data) {
        const { customerCode } = searchQuery;
        const rateBody = RateQueryToRateBody(searchQuery);
        const rate = RateViewResults.data;

        // TODO: verify empty string is good
        SubmitRateResults.mutate({
          ...rateBody,
          datRate: rate,
          suggestedBuyRate,
          reason,
          customerRate,
          customerId: customerCode || '',
        });
      }
    },
    SubmitRateResults,
  };
};

export const useGetCustomerCodes = () => {
  return useQuery(['getCustomerCodes'], () => getCustomerCodes());
};

export const useGetCustomerCodesData = () => {
  let results = useGetCustomerCodes();
  return { ...results, customerCodesResult: results.data };
};
