import { Grid } from "@material-ui/core";
import { Chip, Button, Icon, styled } from "shamrock-clover-ui";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  height: calc(100vh - 98px);
`;

export const SearchCapacityContainer = styled.div`
  height: 100%;
  padding: 16px 64px;
  max-width: 1580px;
`;

export const GridItem = styled(Grid)`
  padding: 8px;
`;


export const StyledChip = styled(Chip)<{backgroundColor: string}>`
  font-size: 16px;
  color: white;
  font-weight: 800;
  max-height: 12px;
  max-width: 34px;
  background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
`
export const StyledResetButton = styled(Button)`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 16px; 
  font-weight: 400; 
`;

export const StyledIconContainer = styled.div`
display: flex;
justify-content: center;
`;

export const StyledCheckmark = styled(Icon)`
  width: 16px;
  height: 16px;
`;