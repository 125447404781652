import { CompanyCapacityInfo } from "./CapacityTypes";

export const searchData: CompanyCapacityInfo[] = [
    {
        score: 84,
        company: "Triton Logistics Inc.",
        loadHistory: 120,
        hq: "MO",
        trucks: 15,
        rtsprouser: true,
        mcleodId: "TRITBOIL",
        mc: "12345",
        dot: "123456",
        name: "Elizabeth Rodriguez",
         phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 70,
        company: "Wolf Pack Trucking",
        loadHistory: 2,
        hq: "KS",
        trucks: 30,
        rtsprouser: true,
        mcleodId: "WOLTKS",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 40,
        company: "Bob's Trucking Co",
        loadHistory: 1,
        hq: "NE",
        trucks: 120,
        rtsprouser: false,
        mcleodId: "BBTCON",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 23,
        company: "Swift Carriers",
        loadHistory: 2,
        hq: "KS",
        trucks: 75,
        rtsprouser: true,
        mcleodId: "SWIFTCC",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 5,
        company: "Midwest Haulers",
        loadHistory: 5,
        hq: "OH",
        trucks: 56,
        rtsprouser: false,
        mcleodId: "MDHAUL",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 42,
        company: "Riley's Rippers",
        loadHistory: 160,
        hq: "OH",
        trucks: 99,
        rtsprouser: false,
        mcleodId: "RILRIP",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 76,
        company: "Scott's Shipping",
        loadHistory: 12,
        hq: "OH",
        trucks: 2,
        rtsprouser: false,
        mcleodId: "SCOTTSHP",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 5,
        company: "Nils' Navigators",
        loadHistory: 62,
        hq: "OH",
        trucks: 75,
        rtsprouser: false,
        mcleodId: "NILNAV",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 92,
        company: "Dawit's Deliveries",
        loadHistory: 98,
        hq: "KS",
        trucks: 400,
        rtsprouser: false,
        mcleodId: "DAWDEL",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 36,
        company: "Austin's Automovers",
        loadHistory: 79,
        hq: "MN",
        trucks: 150,
        rtsprouser: false,
        mcleodId: "AUSTAUT",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 40,
        company: "Dane's Drivers",
        loadHistory: 35,
        hq: "TX",
        trucks: 75,
        rtsprouser: true,
        mcleodId: "DANEDRV",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 5,
        company: "Antonio's Arrivals",
        loadHistory: 320,
        hq: "CH",
        trucks: 350,
        rtsprouser: false,
        mcleodId: "ANTARRS",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 47,
        company: "Todd's Transports",
        loadHistory: 165,
        hq: "FL",
        trucks: 176,
        rtsprouser: true,
        mcleodId: "TODTRANS",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 76,
        company: "Monica's Movers",
        loadHistory: 124,
        hq: "MO",
        trucks: 140,
        rtsprouser: true,
        mcleodId: "MONMOVS",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 46,
        company: "Alicia's Automotion",
        loadHistory: 13,
        hq: "WA",
        trucks: 75,
        rtsprouser: true,
        mcleodId: "ALCAUTM",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
    {
        score: 16,
        company: "Ben's Buses",
        loadHistory: 0,
        hq: "NE",
        trucks: 4,
        rtsprouser: false,
        mcleodId: "BENBUS",
        mc: "#####",
        dot: "#####",
        name: "Elizabeth Rodriguez",
        phone: "(888) 888-8888",
        email: "jcortez@tritonlogistics.com"
    },
   
];