import { SmrTable, styled } from 'shamrock-clover-ui';
import CapacityScoreChip from './CapacityScoreChip';
import { useContext, useState } from 'react';
import { SortOrder, Column, Row } from 'components/cloverTable/TableTypes';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import { StyledCheckmark, StyledIconContainer } from '../CarrierCapacityStyles';

const StyledTextContainer = styled.div`
  justify-content: center;
`;

const ResultText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
`;

const TableContainer = styled.div`
  width: 100%;
  max-height: 565px;
  overflow-y: scroll;
  border-top: 1px solid #cccccc;
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    th {
      border-top: hidden;
      span {
        font-size: 16px;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
    }
  }
  div {
    position: sticky;
    bottom: 0;
  }
`;

const CompanyText = styled.div`
  color: #0091ea;
`;

const SearchCapacityResults = () => {
  const { results } = useContext(CarrierCapacityContext);
  const tableRows: Row[] = results.map((data) => ({
    cells: [
      {
        content: <CapacityScoreChip score={data.score} />,
        value: data.score,
      },
      {
        content: <CompanyText>{data.company}</CompanyText>,
        value: data.company,
      },
      {
        content: data.loadHistory === null ? '_' : data.loadHistory.toString(),
        value: data.loadHistory === null ? '_' : data.loadHistory,
      },
      {
        content: data.hq,
        value: data.hq,
      },
      {
        content: data.trucks.toString(),
        value: data.trucks,
      },
      {
        content: (
          <>
            {data.rtsprouser ? (
              <StyledIconContainer>
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              </StyledIconContainer>
            ) : (
              <></>
            )}
          </>
        ),
        value: data.rtsprouser.toString(),
      },
      {
        content: data.mcleodId,
        value: data.mcleodId,
      },
      {
        content: (
          <>
            MC: {data.mc}
            <br />
            DOT: {data.dot}
          </>
        ),
        value: `${data.mc} ${data.dot}`,
      },
      {
        content: data.name,
        value: data.name,
      },
      {
        content: (
          <>
            {data.phone}
            <br />
            {data.email}
          </>
        ),
        value: `${data.phone} ${data.email}`,
      },
    ],
    onClick: () => console.log('clicked'),
  }));

  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');
  const [sortedTableRows, setSortedTableRows] = useState<Row[]>(tableRows);

  const tableColumns: Column[] = [
    {
      key: 'score',
      label: 'Score',
      isSortable: true,
      width: 7,
    },
    {
      key: 'company',
      label: 'Company',
      isSortable: true,
      width: 12,
    },
    {
      key: 'loadHistory',
      label: 'Load History',
      isSortable: true,
      alignment: 'center',
      width: 9.3,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'left',
      width: 5.1,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: true,
      alignment: 'center',
      width: 6,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: true,
      alignment: 'center',
      width: 9.6,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 11,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 15,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 16,
    },
  ];

  const handleSort = (columnIndex?: number, direction?: SortOrder) => {
    if (columnIndex !== undefined && direction) {
      setSortedColumnIndex(columnIndex);
      setSortDirection(direction);
    }

    const sortedRows = [...sortedTableRows].sort((a, b) => {
      const aValue =
        columnIndex !== undefined ? a.cells[columnIndex].value : '';
      const bValue =
        columnIndex !== undefined ? b.cells[columnIndex].value : '';
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      return 0;
    });
    setSortedTableRows(sortedRows);
  };

  return (
    <>
      <StyledTextContainer>
        <ResultText>{results.length} Matching Carriers</ResultText>
      </StyledTextContainer>
      <TableContainer>
        <SmrTable
          columns={tableColumns}
          rows={sortedTableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
          sortingOptions={{
            onSort: handleSort,
            sortedColumnIndex: sortedColumnIndex,
            sortDirection: sortDirection,
          }}
        />
      </TableContainer>
    </>
  );
};

export default SearchCapacityResults;
