import React, { useContext, useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { Dialog, Button, Icon as CloverIcon } from 'shamrock-clover-ui';
import Icon from './Icon';
import {
  CAMUserStatus,
  DashboardUser,
} from 'features/quoting/utils/camUserServices';
import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { CurrentUser } from 'utils/authUtils';
import LoadingSpinner from './LoadingSpinner';
import { QuotesEnhancementsContext } from 'features/quoting/context/QuotesEnhancementsContext';
import * as FirebaseUtils from '../utils/firebaseUtils';

const DialogContainer = styled.div`
  background-color: none;
  margin-bottom: -49px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconContainer = styled.div`
  margin-bottom: 5px;
`;

const DialogTitle = styled.div`
  text-align: center;
  color: #333333;
  font-size: 24px;
  font-family: 'Proxima Nova';
  font-weight: 400;
  margin-bottom: 22px;
`;

const DialogText = styled.div`
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-family: 'Proxima Nova';
  font-weight: 400;
  margin-bottom: 22px;
  line-height: 22px;
`;

const DialogTextSecondary = styled.div`
  text-align: center;
  color: #333333;
  font-size: 12px;
  font-family: Proxima Nova;
  font-weight: 100;
  margin-top: 22px;
  margin-bottom: 22px;
`;

const SelectContainer = styled.div`
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 5px;
  margin-top: 20px;
`;

const SelectItemText = styled.div`
  font-family: 'Proxima Nova';
`;

const ErrorText = styled.div`
  margin-top: 10px;
  color: red;
`;

interface Props {
  camUsers: DashboardUser[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  curUser: CurrentUser | null;
  isPausing: boolean;
}

const PausedUserSelectDialog: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ isOpen, setIsOpen, camUsers, curUser, isPausing }) => {
  const unassignedOption: DashboardUser = {
    _id: 'unassigned',
    fullName: 'Load Board (New)',
    email: '',
    pausedUser: false,
  };
  const [value, setValue] = useState<DashboardUser>(unassignedOption);
  const [dialogLoading, setDialogLoading] = useState(false);
  const errString = 'Something went wrong, try again.';
  const [error, setError] = useState('');
  const [camUsersNew, setCamUsersNew] = useState<DashboardUser[]>([]);

  const { inactiveUsers, setPausedUserStatus } = useContext(
    QuotesEnhancementsContext,
  );

  React.useEffect(() => {
    const buildCamUsers = async () => {
      setCamUsersNew([unassignedOption, ...camUsers]);
    };

    buildCamUsers();

    //set the assigned person dropdown
    if (curUser) {
      const pausedCurUserObj = inactiveUsers.find((x) => x._id === curUser._id);
      if (pausedCurUserObj) {
        if (pausedCurUserObj.assignToId !== '') {
          const camObj = camUsersNew.find(
            (x) => x._id === pausedCurUserObj.assignToId,
          );
          if (camObj) {
            setValue(camObj);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [camUsers]);

  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const assignedUser = camUsersNew.find((x) => x._id === event.target.value);
    if (assignedUser) {
      setValue(assignedUser);
      updateCAMUser(true, assignedUser, true);
    }
  };

  const updateCAMUser = async (
    isInactive: boolean,
    assignedUser?: DashboardUser,
    assigningToUser?: boolean,
  ) => {
    setError('');
    if (curUser) {
      const body: CAMUserStatus = {
        userId: curUser._id,
        userEmail: curUser.email,
        assignTo: assignedUser ? assignedUser.email : '',
        assignToId: assignedUser ? assignedUser._id : '',
        isInactive: isInactive,
      };

      if (assigningToUser) {
        await setPausedUserStatus(body).catch((err) => {
          setError(errString);
          console.error(err.toString());
        });
        return;
      }

      setDialogLoading(true);
      await setPausedUserStatus(body)
        .then(async () => {
          await FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
            FirebaseUtils.FirebasePages.LIST,
            {
              description: 'Resume Activity',
            },
          );
        })
        .then(async () => {
          setDialogLoading(false);
          setIsOpen(false);
        })
        .catch((err) => {
          setDialogLoading(false);
          setError(errString);
          console.error(err.toString());
        });
    }
  };

  const PausingMessage = () => {
    return (
      <>
        <DialogTitle></DialogTitle>
        <LoadingSpinner size="20" />
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      width="496px"
      height="510px"
      maxHeight="510px"
      closeOnBackdropClick={false}
      content={
        <DialogContainer>
          {isPausing && PausingMessage()}
          {!isPausing && (
            <>
              <IconContainer>
                <Icon name="CamHalfMoon" alt="halfmoon" width={48} />
              </IconContainer>
              <DialogTitle>Your activity is paused</DialogTitle>
              <DialogText>
                Your incoming quotes will now be sent to New, <br />
                or you can assign them to another user.
              </DialogText>
              {!dialogLoading && (
                <Button
                  buttonStyle="outlined"
                  height={'45px'}
                  onClick={() => {
                    updateCAMUser(false, undefined);
                  }}
                >
                  <Icon
                    name="CamResume"
                    alt="CamResume"
                    width={16}
                    padding={'6px'}
                  />
                  <p style={{ fontSize: 14 }}>RESUME ACTIVITY</p>
                </Button>
              )}

              {dialogLoading && <LoadingSpinner size="20" />}

              {error !== '' && <ErrorText>{error}</ErrorText>}

              <DialogTextSecondary>
                Your account will remain paused until you resume activity.
              </DialogTextSecondary>

              <SelectContainer>
                <span style={{ fontSize: 18 }}>Assign Quotes to</span>
                <Select
                  id="dialog-user-selector"
                  style={{
                    color: '#666666',
                    margin: 5,
                    marginLeft: 20,
                  }}
                  data-testid="userSelect"
                  inputProps={{ 'data-testid': 'userSelectInput' }}
                  disableUnderline
                  value={value._id}
                  onChange={onChange}
                  renderValue={(selected) => {
                    return (
                      <Flex>
                        <p style={{ paddingRight: 10, margin: 0 }}>
                          {
                            camUsersNew.find((x) => x._id === selected)
                              ?.fullName
                          }
                        </p>
                      </Flex>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      elevation: 16000000,
                    },
                    style: { zIndex: 350010000, height: 300 },
                    variant: 'menu',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {camUsersNew.map((user, i) => (
                    <MenuItem
                      key={user._id}
                      value={user._id}
                      disabled={
                        inactiveUsers.find(
                          (inactive) => inactive._id === user._id,
                        ) != null
                      }
                      style={{
                        height: 40,
                        padding: 16,
                      }}
                    >
                      <SelectItemText>
                        <Flex>
                          <p style={{ paddingRight: 10, margin: 0 }}>
                            {user.fullName}
                          </p>

                          {value.fullName === user.fullName && (
                            <CloverIcon icon="check" size={'24'} />
                          )}
                          {inactiveUsers.find(
                            (inactive) => inactive._id === user._id,
                          ) && (
                            <Icon
                              alt="pause icon"
                              name="CamPauseDisabled"
                              width={20}
                              height={20}
                            />
                          )}
                        </Flex>
                      </SelectItemText>
                    </MenuItem>
                  ))}
                </Select>
              </SelectContainer>
            </>
          )}
        </DialogContainer>
      }
    />
  );
};

export default PausedUserSelectDialog;
