import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewRateQuery } from 'features/ratePro/ratesTypes';
import { SearchCapacityQuery } from 'features/carrierCapacity/CapacityTypes';

export const useQueryParams = <T extends {}>() => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const addQueryParams = (paramsToAdd: T) => {
    const query = queryString.parse(window.location.search);
    navigate(
      {
        search: queryString.stringify({
          ...query,
          ...paramsToAdd,
        }),
      },
      { replace: true },
    );
  };

  const clearQueryParams = () => {
    navigate(
      {
        search: '',
      },
      { replace: true },
    );
  };

  const setQueryParams = (paramsToAdd: T) => {
    navigate(
      {
        search: queryString.stringify({
          ...paramsToAdd,
        }),
      },
      { replace: true },
    );
  };

  const setPathAndQuery = (type: string, orderId?: T, adminAssigned?: T) => {
    const query = queryString.stringify({
      ...(orderId && { orderId }),
      ...(adminAssigned && { adminAssigned }),
    });

    navigate(`/quotes/${type}?${query}`, { replace: true });
  };

  return {
    addQueryParams,
    clearQueryParams,
    setQueryParams,
    setPathAndQuery,
    queryParams: queryString.parse(search) as T,
  };
};

export const useAuthQueryParams = () =>
  useQueryParams<{
    token: string;
    email: string;
  }>();

export const useQuotingQueryParams = () =>
  useQueryParams<{ orderId?: string; quoteId?: string }>();

export const useRateProQueryParams = () => useQueryParams<NewRateQuery>();

export const useSearchCapacityQueryParams = () => useQueryParams<SearchCapacityQuery>();

export const useRateAdjustmentQueryParams = () =>
  useQueryParams<{
    region?: string;
    lowVanSonarBeginning?: string;
    lowVanSonarEnd?: string;
    averageVanSonarBeginning?: string;
    averageVanSonarEnd?: string;
    highVanSonarBeginning?: string;
    highVanSonarEnd?: string;
    lowReeferSonarBeginning?: string;
    lowReeferSonarEnd?: string;
    averageReeferSonarBeginning?: string;
    averageReeferSonarEnd?: string;
    highReeferSonarBeginning?: string;
    highReeferSonarEnd?: string;
    flatbedDatRate?: string;
    flatbedNumberOfLoads?: string;
    flatbedRtsRate?: string;
    vanNumberOfLoads?: string;
    vanRtsRate?: string;
    reeferNumberOfLoads?: string;
    reeferRtsRate?: string;
    reason?: string;
  }>();

export const useMpactQueryParams = () =>
  useQueryParams<{
    pickupCity?: string;
    pickupState?: string;
    pickupPostalCode?: string;
    deliveryCity?: string;
    deliveryState?: string;
    deliveryPostalCode?: string;
    trailerType?: string;
    includeFuel?: boolean;
    carrierType?: string;
    includeQuartile?: boolean;
  }>();

export const useCreditRequestQueryParams = () =>
  useQueryParams<{
    customerName?: string;
    customerId?: string;
    requestType?: 'new' | 'adjustment';
  }>();
