import {
  Container,
  SearchCapacityContainer,
} from './CarrierCapacityStyles';
import SearchCapacityToolbar from './components/SearchCapacityToolbar';
import SearchCapacityResults from './components/SearchCapacityResults';
import CarrierCapacityWrapper from './context/CarrierCapacityContext';

const SearchCapacity = () => {
  return (
    <CarrierCapacityWrapper>
      <Container>
        <SearchCapacityContainer >
          <SearchCapacityToolbar />
          <SearchCapacityResults />
        </SearchCapacityContainer>
      </Container>
    </CarrierCapacityWrapper>
  );
};

export default SearchCapacity;