import * as React from 'react';
import { styled } from 'shamrock-clover-ui';
import RightArrowGrey from 'assets/images/right-arrow-grey.svg';
import ShipperPortalLogo from 'assets/images/shipper-portal-blue.svg';
import DownArrowBlack from 'assets/images/down-arrow-black.svg';
import RightArrowBlue from 'assets/images/right-arrow-blue.svg';
import DeliveryGrey from 'assets/images/delivery-grey.svg';
import EyeBlue from 'assets/images/eye-blue.svg';
import EyeGrey from 'assets/images/eye-grey.svg';
import PickupGrey from 'assets/images/pickup-grey.svg';
import TruckGrey from 'assets/images/truck-grey.svg';
import XBlack from 'assets/images/x-black.svg';
import RTSProLogo from 'assets/images/rtspro-logo-blue.svg';
import DestinationArrow from 'assets/images/destination-arrow.svg';
import BookItNow from 'assets/images/book-it-now.svg';
import RedAttention from 'assets/images/red-attention.svg';
import NoBookedLoads from 'assets/images/no-booked-loads.svg';
import PickupToDeliveryArrow from 'assets/images/pickup-to-delivery-arrow.svg';
import FilterIcon from 'assets/images/filterIcon.svg';
import CamPauseActive from 'assets/images/cam-pause-active-24.svg';
import CamPauseActiveHover from 'assets/images/cam-pause-active-hover-24.svg';
import CamPauseDefault from 'assets/images/cam-pause-default-24.svg';
import CamPauseDefaultHover from 'assets/images/cam-pause-default-hover-24.svg';
import CamResume from 'assets/images/cam-resume-24.svg';
import CamHalfMoon from 'assets/images/cam-dialog-moon.svg';
import CamPause from 'assets/images/cam-pause.svg';
import CamPauseDisabled from 'assets/images/cam-pause-disabled.svg';

export type ImageName =
  | 'RightArrowGrey'
  | 'ShipperPortalLogo'
  | 'DownArrowBlack'
  | 'RightArrowBlue'
  | 'DeliveryGrey'
  | 'EyeBlue'
  | 'EyeGrey'
  | 'PickupGrey'
  | 'TruckGrey'
  | 'XBlack'
  | 'RTSProLogo'
  | 'DestinationArrow'
  | 'BookItNow'
  | 'RedAttention'
  | 'NoBookedLoads'
  | 'PickupToDeliveryArrow'
  | 'FilterIcon'
  | 'CamPauseActive'
  | 'CamPauseActiveHover'
  | 'CamPauseDefault'
  | 'CamPauseDefaultHover'
  | 'CamResume'
  | 'CamHalfMoon'
  | 'CamPause'
  | 'CamPauseDisabled';

const Images: { [key in ImageName]: string } = {
  RightArrowGrey,
  ShipperPortalLogo,
  DownArrowBlack,
  RightArrowBlue,
  DeliveryGrey,
  EyeBlue,
  EyeGrey,
  PickupGrey,
  TruckGrey,
  XBlack,
  RTSProLogo,
  DestinationArrow,
  BookItNow,
  RedAttention,
  NoBookedLoads,
  PickupToDeliveryArrow,
  FilterIcon,
  CamPauseActive,
  CamPauseActiveHover,
  CamPauseDefault,
  CamPauseDefaultHover,
  CamResume,
  CamHalfMoon,
  CamPause,
  CamPauseDisabled,
};

interface Props {
  name: ImageName;
  onHoverName?: ImageName;
  alt: string;
  height?: number;
  width?: number;
  margin?: string;
  padding?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

interface ImageStyledProps {
  height?: number;
  width?: number;
  margin?: string;
  padding?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

const Image = styled.img<ImageStyledProps>`
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  margin: ${({ margin }) => margin && `${margin}`};
  padding: ${({ padding }) => padding && `${padding}`};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

const Icon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  alt,
  onHoverName,
  ...rest
}) => {
  return (
    <Image
      {...rest}
      src={Images[name]}
      alt={alt}
      onMouseOver={(e) => {
        if (onHoverName)
          e.currentTarget.setAttribute('src', Images[onHoverName]);
      }}
      onMouseOut={(e) => {
        if (onHoverName) e.currentTarget.setAttribute('src', Images[name]);
      }}
    />
  );
};

export default Icon;
