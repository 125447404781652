/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SecondaryNavigation, CloverThemeProvider } from 'shamrock-clover-ui';
import { useNavigate } from 'react-router-dom';

const CapacitySubHeader: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const handleSearchCapacityClicked = () => {
    navigate(`/carrierCapacity`);
  };

  const handleCarriersClicked = () => {
    navigate(`/carrierCapacity/carriers`);
  };

  const tabs = [
    {
      title: 'Search Capacity',
      onClick: handleSearchCapacityClicked,
    },
    {
      title: 'Carriers',
      onClick: handleCarriersClicked,
    },
  ];

  return (
    <CloverThemeProvider>
      <div
        className="secondaryNavigation"
        style={{
          position: 'relative',
          boxShadow: '0 2px 2px 0 rgb(0 0 0 / 22%)',
          overflowX: 'hidden',
        }}
      >
        <SecondaryNavigation tabs={tabs} />
      </div>
    </CloverThemeProvider>
  );
};

export default CapacitySubHeader;
